import { IonContent, IonGrid, IonRow, IonText } from '@ionic/react';
import React from 'react';
import "./Container.css"

const ContactContainer: React.FC = () => {
  return (
    <IonGrid className="after-container">
    <IonRow>
      <IonText className="title">Contact us</IonText>
    </IonRow>
    <IonRow>
      <IonText><a href="mailto:help@gtgcode.com">help@gtgcode.com</a></IonText>
    </IonRow>
    </IonGrid>
  );
};

export default ContactContainer;
