import React, { useEffect } from 'react';
import "./Container.css"
import { IonContent, IonGrid, IonRow, IonText } from '@ionic/react';

const ExploreContainer: React.FC = () => {
  return (
      <IonGrid className="main-page">
      <IonRow>
        <IonText className="title">GTG Code</IonText>
      </IonRow>
      <IonRow>
        <IonText className="subtitle">gotta go</IonText>
      </IonRow>
      </IonGrid>
  );
};

export default ExploreContainer;
